* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "NotoSansKR";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

::-webkit-scrollbar {
  display: none;
}