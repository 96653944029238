@font-face {
  font-family: "NotoSansKR";
  font-weight: 900;
  font-display: swap;
  src: url(./notosans/NotoSansKR-Black.otf);
}

@font-face {
  font-family: "NotoSansKR";
  font-weight: 700;
  font-display: swap;
  src: url(./notosans/NotoSansKR-Bold.otf);
}

@font-face {
  font-family: "NotoSansKR";
  font-weight: 500;
  font-display: swap;
  src: url(./notosans/NotoSansKR-Medium.otf);
}

@font-face {
  font-family: "NotoSansKR";
  font-weight: 400;
  font-display: swap;
  src: url(./notosans/NotoSansKR-Regular.otf);
}

@font-face {
  font-family: "NotoSansKR";
  font-weight: 300;
  font-display: swap;
  src: url(./notosans/NotoSansKR-Light.otf);
}

@font-face {
  font-family: "NotoSansKR";
  font-weight: 100;
  font-display: swap;
  src: url(./notosans/NotoSansKR-Thin.otf);
}
